.container {
  height: 6.7rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: none;
  padding: 0 2rem;

  .copyright {
    > img {
      height: 3.2rem;
      width: 3.2rem;
      margin: 0 1.6rem;
    }
    > span {
      opacity: 0.6;
      color: white;
      font-size: 0.933rem;
    }
  }
  .actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: white;
    .links {
      > * {
        opacity: 0.6;
        text-decoration: none;
        color: white;
        font-size: 0.933rem;
        margin: 0 0.8rem;
      }
      margin-right: 0.533rem;
    }
    .socialIcon {
      margin: 0 0.533rem;
      color: white;
      font-size: 1.6rem;
    }
  }
}
