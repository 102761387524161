.container {
  .cardIcons {
    display: flex;
    flex-direction: row;
    align-items: center;

    .link {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .icon {
      margin-right: 8px;
      color: white;
      font-size: 20px;
    }

    img {
      height: 17px;
    }
  }

  .packshotFilters {
    display: flex;
    align-items: center;

    > * {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.iconButton {
  background-color: transparent;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 30px;
  padding: 0.75rem;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 1.07rem;
  transition: all 0.3s;
  border: none;

  * + * {
    margin-left: 5px;
  }

  * {
    cursor: inherit;
  }

  &:hover,
  &.active {
    background-color: #000;
    color: white;
  }
}

// div.carousel {
//   width: 100%;
//   height: calc(100% - 160px);
//   top: 160px;
//   padding-bottom: 0;
//   [class*='ant-modal-content'] {
//     background: white;
//     width: 100%;
//     height: 100%;
//     border-radius: 0;
//     [class*='ant-modal-body'] {
//       width: 100%;
//       height: 100%;
//       padding: 0;
//     }
//   }

//   .carouselIcons {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     .link {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//     }
//   }
// }

.carouselWrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  .carouselActions {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .close {
    height: 12px;
    width: 12px;
  }

  .carousel {
    padding: 2rem 2rem 0rem 2rem;
  }

  &.open {
    transform: translateY(0);
  }
  .carouselIcons {
    display: flex;
    flex-direction: row;
    align-items: center;

    .link {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
