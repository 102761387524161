.container {
  padding: 16px 24px;
  border-bottom: rgba(41, 44, 51, 0.1) solid 1px;
  display: flex;
  align-items: center;
  .logo {
    margin-right: 32px;
  }
  .breadcrumb {
    flex: 1;
  }
  .close {
    height: 12px;
  }
}
