.container {
  width: 100%;
  display: flex;
  align-items: center;
  .iconContainer {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    border: solid 2px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    > img {
      width: 20px;
      height: 20px;
    }
  }
  .text {
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
  }
  .closeButtonContainer {
    margin-left: auto;
    .closeButton {
      width: 12px;
      justify-self: flex-end;
    }
  }
}
