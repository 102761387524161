@import 'src/styles/variables.scss';

.layout {
  background-color: white !important;
}

.modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .logo {
    color: $primary;
    opacity: 1;
    width: 4rem;
  }

  .spinLogo {
    display: flex;
    position: relative;
    color: $primary;

    .spin {
      font-size: 3.3rem;
      margin-top: 0.4rem;
    }

    .logo {
      align-self: center;
      // margin-left: -3.6rem;
    }
  }

  .subtitle {
    font-size: 1rem;
    width: 26rem;
    margin-top: 1rem;
  }

  .cancel {
    text-transform: uppercase;
    margin-top: 1.1rem;
    font-size: 0.8rem;
    color: $primary;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .addButton {
    .buttonContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .buttonIcon {
        margin-right: 8px;
      }
    }
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: white;

  .viewer {
    flex: 0.63;
    max-height: 85vh;
  }

  .summary {
    flex: 0.37;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    max-height: 85vh;

    .title {
      font-size: 1.2rem;
      margin-bottom: 2.1rem;
    }

    .renderSettings {
      flex: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      margin: 16px 0;
      > * {
        margin-bottom: 32px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
      height: 48px;
      align-items: center;

      .left {
        font-size: 1rem;
        color: black;
      }

      .right {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: auto;

      .button {
        width: 48%;
      }
    }
  }
}
