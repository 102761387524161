$lightContainerHover: rgba(0, 0, 0, 0.07);
$darkContainerHover: rgba(0, 0, 0, 0.5);
$lightContainerActive: rgba(0, 140, 160, 0.1);
$darkContainerActive: #000;

.container {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 8px 0;
  width: 72px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover,
  &:focus {
    outline: none;
  }

  .text {
    font-family: 'Inter';
    font-size: 0.8rem;
    line-height: 1.07rem;
    white-space: nowrap;
    user-select: none;
  }

  .icon {
    svg {
      height: 18px;
    }
  }

  &.light {
    &:hover,
    &:focus {
      background-color: $lightContainerHover;
    }
    &:active {
      background-color: $lightContainerActive;
    }
    .text {
      color: #000;
    }
  }

  &.dark {
    &:hover,
    &:focus {
      background-color: $darkContainerHover;
    }
    &:active {
      background-color: $darkContainerActive;
    }
    .text {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &.selected {
    &.light {
      .text {
        color: #151515;
      }
      background-color: rgba(0, 140, 160, 0.15);
    }
    &.dark {
      .text {
        color: #fff;
      }
      background-color: #000;
    }
  }
}
