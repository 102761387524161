@import 'src/styles/variables';

$preview-background: #f4f4f4;
$border-color: rgba(0, 0, 0, 0.1);

.container {
  margin-right: 24px;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    height: 100%;
    width: 100%;
  }

  .preview {
    background-color: $preview-background;
    height: calc(100vh - 220px); // 100vh minus top bar, paddings and bottom bar
    width: 100%;
    border: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;

    img.previewImg {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .productViewer {
      height: 100%;
      width: 100%;

      &.visible {
        display: block;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .imageButtons {
    height: 100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    overflow: hidden;

    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      padding: 0;
      margin: 0;
      * {
        color: black;
      }
    }

    .imageButton {
      height: 74px;
      width: 74px;
      background-color: white;
      margin-right: 16px;
      margin-top: 16px;
      border: 1px solid $border-color;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        border: 2px solid black;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.playerLogo {
          width: 45%;
          height: 45%;
          object-fit: contain;
        }
      }
    }
  }

  .spin {
    font-size: 4rem;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .container {
    margin-right: 0px;
    .imageButtons {
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      overflow: hidden;
      margin-bottom: 12px;
    }
  }
}
