.layout {
  background-color: white !important;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: white;

  .viewer {
    flex: 0.63;
    max-height: 85vh;
  }

  .settings {
    flex: 0.37;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    max-height: 85vh;

    .title {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    [class*='ant-tabs'] {
      flex: 1;
    }

    [class*='ant-tabs-bar'] {
      margin: 0;
      padding: 0;
      background-color: transparent;
      flex: 1;
      display: flex;
      flex-direction: row;
    }

    [class*='ant-tabs-content'] {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0;
      > div[class*='ant-tabs-tabpane ant-tabs-tabpane-active'] {
        padding: 0;
        display: flex;
        flex-direction: column;
      }
    }

    [class*='ant-form-item'] {
      margin-bottom: 0.2rem;
    }
  }
}
