@import '../../../styles/colors';

.container {
  width: 100%;
  height: 100%;
  position: relative;

  .loading {
    z-index: 1000;
    background: #fff;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .productLoading {
    z-index: 1000;
    background: transparent;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      color: white;
    }
  }
}

// black button
button.okButton {
  flex: 1;
  background-color: $black;
  border-color: $black;
  &:hover,
  &:active,
  &:focus {
    border-color: $black;
    background-color: $black-lighten;
  }
}

// ghost button
button.cancelButton {
  flex: 1;
  transition: border ease 0.3s;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.5);
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $black;
    color: $black;
  }
}
