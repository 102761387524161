.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .field {
    margin-top: 8px !important;
  }

  .overIcon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s, background-color 0.3s;
    color: #fff;
    z-index: -1;
  }

  .imagePlaceholder {
    height: 48px;
    width: 48px;
    border-radius: 4px;
    background-color: #f4f4f4;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-right: 16px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
      display: block;
    }

    &.selected {
      border: 2px solid #000000;
    }
  }

  .infoAndButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .infoContainer {
      display: flex;
      flex-direction: row;
      user-select: none;

      &:hover {
        .imagePlaceholder {
          .overIcon {
            opacity: 1;
            z-index: 1;
          }
        }
      }

      button {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        padding: 0;
        margin: 0;
      }

      .headerContainer {
        width: 48px;
        height: 48px;
        background-color: #f4f4f4;
        margin-right: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }

      .info {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 15px;
          line-height: 22px;
          font-weight: bold;
        }

        .desc {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }

    .closeButton {
      width: 12px;
      justify-self: flex-end;
    }
  }

  .customCamera {
    width: 100%;
    text-align: left;
    > * + * {
      margin-left: 8px;
    }
  }
}
