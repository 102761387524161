$containerWidth: 248px;

.container {
  position: absolute;
  bottom: 7.4rem;
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: $containerWidth;
  left: calc(50% - (#{$containerWidth} / 2));
  height: 0;
  border-radius: 8px;
  transition: all ease 0.2s;
  overflow: hidden;
  &.open {
    height: 72px;
    padding: 5px;
  }
}
