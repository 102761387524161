.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  height: 100%;
  position: relative;

  * {
    user-select: none;
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
    }
  }

  .content {
    margin-top: 15px;
    margin-bottom: 15px;
    max-height: 100%;
    overflow-y: scroll;
    padding-right: 5px;
    > * {
      margin-bottom: 32px;
    }
  }

  .addProductButton {
    .buttonContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .buttonIcon {
        margin-right: 8px;
      }
    }
  }
}
