.close {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 1rem;
  background-color: #f9f9f9;

  .closeButton {
    width: 1rem;
  }
}

.content {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;

  .newAmbianceLogo {
    width: 5rem;
  }

  .title {
    display: flex;
    text-align: center;
    font-weight: bold;
    line-height: 3rem;
    font-size: 2.1rem;
    width: 17rem;
    margin-top: 1rem;
  }

  .subtitle {
    display: flex;
    text-align: center;
    font-size: 1rem;
    width: 19rem;
    opacity: 0.7;
    margin-top: 1rem;
  }
}

.loading {
  z-index: 1000;
  background: #fff;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
