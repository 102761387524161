$MEDIA_WIDTH: 17.5rem;
$MEDIA_HEIGHT: 12.5rem;

$container-padding: 6em;

$primary: #151515;
$primary-light: #73d7e1;
$primary-dark: #004f5d;
$secondary: #f2b230;
$tertiary: #3c82ff;
$error: #ff8c64;

$background-default: #f9f9f9;

$description: #000000;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-2xl: 1600px;
$breakpoint-3xl: 2200px;
