$containerHeigh: 376px;
$titleColor: #000;
$unitColor: #151515;
$modalBackground: #fff;

.container {
  [class*='ant-modal-body'] {
    height: $containerHeigh;
    border-radius: 8px;
    background-color: $modalBackground;
    padding: 2.666rem;
    box-shadow: 0 16px 32px 0 rgba(41, 44, 51, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: $titleColor;
    font-family: 'Inter';
    font-size: 1.266rem;
    font-weight: bold;
    line-height: 1.6rem;
    text-align: center;
  }

  .selectUnit {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unit {
    margin: 0 0.666rem;
    color: $unitColor;
    font-family: 'Inter';
    font-size: 0.733rem;
    font-weight: 500;
    letter-spacing: 0.066rem;
    line-height: 1.066rem;
    text-transform: uppercase;
  }
}
