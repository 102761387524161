@import 'src/styles/variables';

:root {
  background-color: $background-default;
}

.loading {
  height: 100vh;
  width: 100%;
  color: $primary;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
