.container {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  .header {
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
    align-items: center;

    .title {
      flex: 1;
      color: #000000;
      font-size: 19px;
      font-weight: bold;
      line-height: 24px;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      height: 16px;
      width: 16px;
      padding: 0;
      margin: 0;
      .icon {
        height: 100%;
        width: 100%;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .description {
    padding-bottom: 16px;
  }
}
