$textColor: #fff;
$actionsWidth: 388px;

.container {
  .infos {
    position: absolute;
    top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    user-select: none;
    span {
      color: $textColor;
      font-family: 'Inter';
      font-size: 0.8rem;
      letter-spacing: 1.7px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .actions {
    position: absolute;
    bottom: 2rem;
    left: calc(50% - (#{$actionsWidth} / 2));
    height: 0;
    width: $actionsWidth;
    transition: all ease 0.2s;
    overflow: hidden;
    display: flex;
    justify-content: center;
    > * {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
    &.open {
      height: 3.3rem;
    }
  }
}
