@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $background-default;
  height: 100%;

  .studioLogo {
    width: 230px;
  }

  .welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    font-size: 15px;
    color: $description;
  }
}
