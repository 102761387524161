.container {
  .grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 330px);
    grid-gap: 24px;
  }

  .card {
    width: 100%;
    height: 100%;
    > * {
      height: 100%;
    }
  }

  .uploadContainer {
    position: relative;
    .upload {
      position: absolute;
      right: 0px;
      top: -65px;
      [class*='ant-upload'] {
        button {
          box-shadow: none;
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
}
