@import 'src/styles/variables';

$buttonBorder: #fff;
$buttonText: #fff;
$buttonDisabledbackground: rgba(0, 0, 0, 0.15);
$toolbarBackground: rgba(0, 0, 0, 0.6);
$toolbarHeight: 72px;
$toolbarWidth: 90%;

.container {
  display: flex;
  align-items: center;
  background-color: $toolbarBackground;
  padding: 5px;
  border-radius: 8px;
  width: 90%;
  height: $toolbarHeight;
  position: absolute;
  margin-left: calc((100% - #{$toolbarWidth}) / 2);
  transition: all 0.2s ease;

  &.bottom {
    bottom: 15px;
  }
  &.top {
    // tollbar container size + top margin
    bottom: calc(100% - (72px + 15px));
  }

  .leftPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      margin-left: 15px;
    }
    .updown {
      margin-left: 24px;
    }
  }

  .middlePanel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btnSave {
      border: 1px solid $buttonBorder;
      color: $buttonText;
      width: 124px;
      border-radius: 4px;
      height: 35px;
      opacity: 1;
      font-size: 0.8rem;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 24px;
      text-align: center;
      background: transparent;
      text-transform: uppercase;
      margin: 0px 10px;
      cursor: pointer;
      user-select: none;
      &:hover,
      &:active,
      &:focus {
        &:not(:disabled) {
          background-color: $buttonDisabledbackground;
        }
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btnClose {
      margin: 15px;
    }
  }

  .subbar {
    position: absolute;
    transition: all 0.2s ease;
    left: calc(50% - 221px);
    &.bottom {
      // toolbar size + toolbar margin
      bottom: calc(#{$toolbarHeight} + 16px);
    }
    &.top {
      // both toolbar and subbard size + top margin
      bottom: calc(100% - ((#{$toolbarHeight} * 2) + 15px));
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    margin-top: 20px;
    .subbar {
      position: fixed;
      top: 0;
      left: 0px;
    }
    .middlePanel {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
