$textColor: #151515;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .spinnerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 1.066rem;

    .spinner {
      font-size: 3.3rem;
      color: $textColor;
    }

    .icon {
      // position: absolute;
      top: -3px;
    }
  }

  h1 {
    color: #000000;
    font-family: 'Inter';
    font-size: 1.266rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.066rem;
    margin-top: 1rem;
  }

  h2 {
    text-align: center;
    color: #000000;
    font-family: 'Inter';
    font-size: 1rem;
    font-weight: normal;
  }

  .actions {
    margin-top: 1.6rem;
  }
}
