@import 'src/styles/variables';

.container {
  height: calc(100vh - 80.5px - 48px); // screen size - header size - padding layout

  .spin {
    font-size: 4rem;
    margin: auto;
  }

  .informationsWrapper {
    height: calc(100vh - 220px);
  }

  .content {
    display: flex;
    justify-content: center;
    height: 100%;

    * {
      user-select: none;
    }

    .description {
      flex: 1;
      display: flex;
      flex-direction: column;

      .colorPicker {
        padding-bottom: 2.133rem;
      }

      .buttons {
        display: flex;
        align-items: flex-end;
        height: 100px;

        > button {
          flex: 1;
          margin-right: 1.6rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .container {
    height: 100%;

    .informationsWrapper {
      height: 100%;
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      * {
        user-select: none;
      }
    }
  }
}
