$containerHeigh: 388px;
$containerWidth: auto;
$modalBackground: #fff;
$textColor: #000;

.container {
  [class*='ant-modal-body'] {
    height: $containerHeigh;
    width: $containerWidth;
    border-radius: 8px;
    background-color: $modalBackground;
    padding: 2.666rem 2rem;
    box-shadow: 0 16px 32px 0 rgba(41, 44, 51, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    width: 100%;
    color: $textColor;
    font-family: 'Inter';
    font-size: 1rem;
    text-align: center;
  }
}
