$catalogHeight: 256px;
$toolbarHeight: 72px;
$containerWidth: 90%;
$backgroundColor: #fff;
$borderColor: rgba(41, 44, 51, 0.1);

.container {
  position: absolute;
  width: $containerWidth;
  left: calc((100% - #{$containerWidth}) / 2);
  height: 0;
  background-color: $backgroundColor;
  border-radius: 8px;
  transition: all ease 0.2s;
  overflow: hidden;
  &.open {
    height: $catalogHeight;
    padding: 1.066rem;
  }
  &.fullScreen {
    height: 100vh;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 0 2rem;
    border-radius: 0;
    overflow-y: scroll;
  }
  &.bottom {
    // toolbar size + toolbar margin + toolbar offset
    bottom: calc(#{$toolbarHeight} + 16px + 15px);
  }
  &.top {
    // both toolbar and catalog size + top margin + toolbar margin
    bottom: calc(100% - ((#{$toolbarHeight} + #{$catalogHeight}) + 16px + 15px));
  }

  .header {
    width: 100%;
    border-bottom: 1px solid $borderColor;
    .logo {
      width: 2.666rem;
      height: 2.666rem;
      margin: 1.266rem 2.133rem;
    }
  }

  .actions {
    position: absolute;
    right: 1.066rem;
    top: 1.066rem;
    display: flex;
    align-items: center;
    > * {
      margin: 0 9px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  h1 {
    margin: 0;
    color: #000;
    font-family: 'Inter';
    font-size: 1.2rem;
    font-weight: bold;
    &.fullScreenTitle {
      margin: 1.6rem 0 0 1.6rem;
    }
  }

  .search {
    margin: 1.6rem;
    width: 50%;
  }

  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    .loading {
      font-size: 3.2rem;
    }
  }

  .waypoint {
    height: 3rem;
  }
}
