.container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: black;
  font-size: 50px;

  .a {
    color: cornflowerblue;
    font-size: 25px;
    margin-top: 20px;
  }
}