$headerSize: 73px;

div.container {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  [class*='ant-modal-content'] {
    width: 100%;
    height: 100%;
    border-radius: 0;
    [class*='ant-modal-body'] {
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
  .content {
    height: calc(100vh - #{$headerSize});
    padding: 1.6rem;
    flex: 1;
    display: flex;
    .description {
      flex: 1;
      display: flex;
      flex-direction: column;
      .informationsContainer {
        height: 80vh;
      }
      .actions {
        display: flex;
        align-items: flex-end;
        height: 100px;
        > button {
          flex: 1;
          margin-right: 1.6rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
