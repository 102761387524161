$productThumbnailSize: 160px;
$borderColor: rgba(0, 0, 0, 0.1);
$textColor: #000;

.container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 5px;
  > * {
    margin: 0.8rem 1.066rem 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
  .productCard {
    cursor: pointer;
    width: $productThumbnailSize;
    height: calc(#{$productThumbnailSize} + 8px + 16px);
    .productThumbnail {
      height: $productThumbnailSize;
      width: $productThumbnailSize;
      border: 1px solid $borderColor;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .productName {
      margin-top: 8px;
      color: $textColor;
      font-size: 0.8rem;
      line-height: 1.066rem;
      width: 100%;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
