@import '../../../styles/colors';

.container {
  padding: 24px 18px;
}

// black button
button.okButton {
  flex: 1;
  background-color: $black;
  border-color: $black;
  &:hover,
  &:active,
  &:focus {
    border-color: $black;
    background-color: $black-lighten;
  }
}

// ghost button
button.cancelButton {
  flex: 1;
  transition: border ease 0.3s;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.5);
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $black;
    color: $black;
  }
}
