.container {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .studioLogo {
    width: 230px;
  }

  .description {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    font-size: 15px;
    color: #000000;
    width: 24rem;
    text-align: center;
  }

  .button {
    width: 240px;
  }

}
