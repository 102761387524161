.deleteContainer {
  height: 100%;

  > *:not(:last-child) {
    margin-right: 12px;
  }

  .icon {
    height: 100%;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(1);
    }
  }
}
