@import 'src/styles/variables';

$containerHeight: 72px;
$backgroundColor: #fff;

.container {
  transition: all ease-in 0.2s;
  height: 0;
  width: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  &.open {
    height: $containerHeight;
  }
  .barContainer {
    padding: 5px;
    border-radius: 8px;
    height: 100%;
    background-color: $backgroundColor;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .container {
    display: flex;
    justify-content: center;
    left: 0px;
    bottom: 80px;
    &.open {
      height: 200px;
    }
    .barContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
