$preview-background: #f4f4f4;
$border-color: rgba(0, 0, 0, 0.1);

.container {
  flex: 70%;
  height: 100%;
  width: 100%;

  .preview {
    background-color: $preview-background;
    height: 100%;
    width: 100%;
    border: 1px solid $border-color;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .productViewer {
      &.visible {
        display: block;
      }

      &.hidden {
        display: none;
      }
    }
  }
}
