.products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 330px);
  justify-content: center;
  grid-gap: 24px;
  .product {
    width: 100%;
  }
}

@media (max-width: 1085px) and (min-width: 937px), (max-width: 740px) and (min-width: 632px) {
  .products {
    grid-template-columns: repeat(auto-fit, 280px);
  }
}
