$textColor: #fff;
$actionsWidth: 388px;

.container {
  position: absolute;
  bottom: 7rem;
  left: calc(50% - (#{$actionsWidth} / 2));
  height: 0;
  width: $actionsWidth;
  transition: all ease 0.2s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &.open {
    height: 3.3rem;
  }
}
