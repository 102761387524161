.container {
  height: 100%;
  width: 100%;
  position: relative;

  .loading {
    z-index: 1000;
    position: absolute;
    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
  }

  .actions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    > * {
      position: absolute;
      z-index: 1000;
    }
  }

  .instructions {
    text-align: center;
    margin-top: 3rem;
    width: 100%;
    height: auto;
    top: 0;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .pickCamera {
    right: 2rem;
    bottom: 2rem;

    width: 4.8rem;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      height: 1.5rem;
    }
  }
}
