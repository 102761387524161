$black: #000;
$white: #fff;

$white-60: transparentize($white, 0.4);
$black-50: transparentize($black, 0.5);

$black-lighten: lighten($black, 15%);

$category-yellow: #f2b230;
$category-blue: #3c82ff;
$category-cyan: #73d7e1;
$category-dark-blue: #004f5d;
