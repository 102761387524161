.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    width: 100%;
    height: 6rem;
    margin-bottom: 2rem;
    .productTitle {
      height: 100%;
      flex: 1;
      text-overflow: ellipsis;
      color: #000;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 2rem;
    }

    .brandLogo {
      width: 6rem;
      height: 6rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-right: 1.6rem;
      border-radius: 4px;
      &.defaultLogo {
        padding: 0.8rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.details {
  flex: 1;
  color: black;
  overflow-y: scroll;
  padding-right: 5px;
  .section {
    margin-bottom: 1.6rem;
  }

  .price {
    font-size: 1.266rem;
    font-weight: normal;
  }

  .bordered {
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }
}

.bold {
  font-weight: bold;
}

.title {
  font-size: 1.266rem;
  font-weight: bold;
  line-height: 1.266;
  margin-bottom: 1.066rem;
}

.information {
  font-size: 0.933rem;
  line-height: 1.333rem;
  margin-bottom: 1.066rem;
}

.desc {
  white-space: pre-wrap;
}

.loader {
  font-size: 3.2rem;
  margin: auto;
}
