.container {
  height: 3.2rem;
  margin-top: 2rem;
  width: 100%;

  .button {
    font-size: 0.8rem;
    height: 100%;
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
    letter-spacing: 1px;
  }
}