.container {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: all ease 0.2s;
  display: flex;
  align-items: center;
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
  &:active {
    svg {
      transform: scale(0.9);
    }
  }
}
