.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(85vh - 100.5px);
  overflow-y: auto;

  @media (max-width: 1920px) {
    height: calc(85vh - 100.5px);
  }

  .form {
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .rowItem {
        width: 48%;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;

    .button {
      width: 48%;
    }
  }
}
