.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 24rem;
  margin-top: 2rem;

  .form {
    width: 100%;

    [class*='ant-form-item'] {
      margin-bottom: 0;
    }
  }

  .submit {
    margin-top: 2rem;
    width: 100%;
    height: 3rem;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .cancelContainer {
    display: flex;
    justify-content: center;
    align-items: content;
    margin-top: 1rem;
  }

  .cancel {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #151515;
  }
}