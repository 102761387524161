.form {
  width: 100%;

  [class*='ant-form-item'] {
    margin-bottom: 0;
  }
}

.forgotContainer {
  margin-top: 0.5rem;

  .forgotPassword {
    color: #151515;
    font-size: 0.9rem;
  }
}
